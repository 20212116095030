import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem;
`
const StyledColumns = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`
const StyledColumn = styled.div`
    flex: 0 0 45%;
    @media (max-width: 768px) {
        flex: 0 0 100%;
      h2{
        margin-bottom: 0;
      }
    }

`
const columnsContent = [
    {
        title: 'ZELENA ENERGIJA',
        text: 'Projektiramo, financiramo in izvajamo projekte prehoda na zelene vire energije v stanovanjskih, javnih in poslovnih objektih.',
        color: '#F7C92A',
        link: '/zelena-energija/',
    },
    {
        title: 'ZADRUŽNI MODEL',
        text: 'Naš cilj je prelivanje poslovnih presežkov v korist članov zadruge (nižja cena energije) in razvoj zadruge, ne pa delitev dobička med lastnike.',
        color: '#D69C24',
        link: '/zadruzni-model/',
    },
    {
        title: 'ENERGETSKA SAMOOSKRBA',
        text: 'S projekti energetske samooskrbe, v lokalnih okoljih ali regijah vzpostavljamo nove gospodarske aktivnosti in preprečimo odtekanje denarja iz teh območij.',
        color: '#ED9D34',
        link: '/energetska-samooskrba/',
    },
    {
        title: 'IZVEDENI PROJEKTI',
        text: 'Do sedaj smo uspešno izvedli že 5 večjih projektov daljinskega ogrevanja na lesno biomaso.',
        color: '#D66F24',
        link: '/projekti/',
    },
]
// markup
const IndexPage = () => {
    const columns = columnsContent.map((column) => Column(column))
    return (
        <Layout home>
            <StyledLayout>
                <TitleBlock>
                    <h1>SONČNA ZADRUGA</h1>
                    <h2>Skupaj do zelene energije in energetske samooskrbe.</h2>
                </TitleBlock>
                <StyledColumns> {columns}</StyledColumns>
            </StyledLayout>
        </Layout>
    )
}

export default IndexPage

const Column = ({ title, text, color, link }) => {
    return (
        <StyledColumn key={title}>
            <Link to={link}>
                <h2 style={{ color: color, fontSize: '1.6rem' }}>{title}</h2>
            </Link>
            <p>{text}</p>
        </StyledColumn>
    )
}

const TitleBlock = styled.div`
    h1 {
        font-weight: 500;
        letter-spacing: 3px;
      @media (max-width: 900px) {
        font-size:1.8em;
      }
    }
  h2{
    @media (max-width: 900px) {
      margin-top:0.2rem;
    }

  }
  text-align: center;
    @media (max-width: 900px) {
        text-align: left;
    }
`
